<template>
  <el-dialog
    :title="$t('ReadFull')"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    width="900px"
    class="read-fulltext"
  >
    <!-- 标题区域 -->
    <h3 class="title-box">{{ messageDetail.title }}</h3>

    <!-- 内容区域 -->
    <p class="content-box">{{ messageDetail.content }}</p>

    <!-- 图片和附件 -->
    <div class="img-attachment-box">
      <!-- 图片 -->
      <div
        v-if="messageDetail.imageList && messageDetail.imageList.length"
        class="img-list"
      >
        <el-image
          v-for="(item, index) in messageDetail.imageList"
          :key="index"
          :src="item"
          :preview-src-list="messageDetail.imageList"
          class="img-box"
        >
        </el-image>
      </div>
      <!-- 附件 -->
      <ul
        v-if="messageDetail.attachmentList && messageDetail.attachmentList.length"
        class="attachment-list"
      >
        <li
          class="attachment"
          v-for="(item, index) in messageDetail.attachmentList"
          :key="index"
          @click="downloadFile(item)"
        >
          <i class="el-icon-paperclip"></i>
          <span>{{ $t('Attachments') }}{{ index + 1 }}</span>
        </li>
      </ul>
    </div>

    <!-- 群发消息 -->
    <template v-if="messageDetail.messageType === 1 && messageDetail.isCheckAll === 0">
      <div class="visible-box">
        <span>{{ $t('Visiable') }}：</span>
        <span class="group-name">{{ messageDetail.visibleGroup }}</span>
      </div>
      <div class="invisible-box">
        <span>{{ $t('Hide') }}：</span>
        <span class="group-name">{{ messageDetail.noVisibleGroup }}</span>
      </div>
    </template>
    <template v-else-if="messageDetail.messageType === 1 && messageDetail.isCheckAll === 1">
      <div class="possessor">{{ $t('SendPossessor') }}</div>
    </template>
    <!-- 私发消息 -->
    <template v-else-if="messageDetail.messageType === 2">
      <div class="visible-box hairpin">
        <span>{{ $t('Visiable') }}：</span>
        <span class="group-name">{{ messageDetail.visibleGroup }}</span>
      </div>
    </template>
    <div class="publisher-box">
      <span>{{ $t('Publisher') }}：</span>
      <span>{{ messageDetail.issueName }}</span>
    </div>

    <span slot="footer">
      <el-button @click="cancelFn">{{ $t('Off') }}</el-button>
      <el-button
        class="member-status-btn"
        @click="submitData(messageDetail)"
      >{{ $t('MemberStatus') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { deepCopy } from "@/utils/tool.js";
import api from "@/fetch/api";

export default {
  name: "ReadFulltext",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show;
      },
      set() {
        this.cancelFn();
      },
    },
  },
  data() {
    return {
      messageDetail: {}, // 消息详情
    };
  },
  created() {
    this.getMessageDetail();
  },
  methods: {
    // 获取消息详情
    getMessageDetail() {
      const locale = sessionStorage.getItem("locale");
      const languageObj = {
        "zh-CN": "Chinese",
        "en-US": "English",
        "bahasa-Indonesia": "Bahasa",
        "original-text": "non",
      };
      api
        .querySendMessageById({
          id: this.data.data.id,
          language: languageObj[locale],
        })
        .then((res) => {
          const copyObj = deepCopy(res.data);
          if (copyObj.imageList) {
            copyObj.imageList = copyObj.imageList.split(",");
          } else {
            copyObj.imageList = [];
          }
          if (copyObj.attachmentList) {
            copyObj.attachmentList = copyObj.attachmentList.split(",");
          } else {
            copyObj.attachmentList = [];
          }
          // isCheckAll为0，选择了特定组、isCheckAll为1，发送所有人
          if (copyObj.messageType === 1 && copyObj.isCheckAll === 0) {
            // 群发消息
            copyObj.visibleGroup = this.handleGroup(copyObj.visibleList);
            copyObj.noVisibleGroup = this.handleGroup(copyObj.noVisibleList);
          } else if (copyObj.messageType === 2) {
            // 私发消息
            copyObj.visibleGroup = this.handleGroup(copyObj.visibleList);
          }
          this.messageDetail = copyObj;
        });
    },

    // 处理分组
    handleGroup(arr) {
      if (arr.length) {
        const groupName = [];
        arr.forEach((item) => {
          groupName.push(item.groupName);
        });
        return groupName.join(",");
      } else {
        return "-";
      }
    },

    // 下载文件
    downloadFile(item) {
      const link = document.createElement("a");
      link.href = item;
      link.click();
    },

    // 取消
    cancelFn() {
      this.$emit("update:show", false);
    },

    // 确定
    submitData(obj) {
      const titleObj = {
        title: obj.title,
        content: obj.content,
        messageType: obj.messageType,
        id: obj.id,
      };
      this.$router.push({
        name: "MemberStatus",
        query: { titleObj: JSON.stringify(titleObj) },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.read-fulltext {
  ul,
  li {
    list-style: none;
  }

  // 标题区域
  .title-box {
    margin-bottom: 20px;
    text-align: center;
  }

  // 内容区域
  .content-box {
    min-height: 80px;
    max-height: 150px;
    overflow-y: auto;
    // 滚动条宽度为0（兼容火狐）
    //scrollbar-width: none;
    // 滚动条宽度为0
    /*&::-webkit-scrollbar {
      display: none;
    }*/
    padding: 10px 15px;
    margin-bottom: 20px;
    font-size: 14px;
    border: 1px solid #6e6e6e;
    border-radius: 5px;
  }

  // 图片和附件
  .img-attachment-box {
    display: flex;
    .img-list {
      width: 280px;
      .img-box {
        width: 80px;
        height: 80px;
        margin: 0 10px 10px 0;
      }
    }
    .attachment-list {
      flex: 1;
      margin-left: 150px;
      .attachment {
        margin-bottom: 15px;
        cursor: pointer;
        & > span {
          margin-left: 5px;
          font-size: 14px;
        }
      }
      .attachment:last-child {
        margin-bottom: 0;
      }
      .attachment:hover {
        color: #409fff;
      }
    }
  }

  .visible-box,
  .invisible-box {
    display: flex;
    & > .group-name {
      flex: 1;
    }
  }
  .visible-box {
    margin-top: 20px;
  }
  .invisible-box {
    margin: 20px 0;
    color: #c1312b;
  }
  .possessor {
    margin: 20px 0;
  }
  .hairpin {
    margin-bottom: 20px;
  }

  .member-status-btn {
    background-color: #589ef8;
    color: #ffffff;
  }
}
</style>
<style lang="scss">
.read-fulltext {
  .el-dialog__header,
  .el-dialog__footer {
    text-align: center;
  }
  .el-dialog__body {
    padding: 20px;
  }
  .el-dialog__footer {
    .el-button {
      margin: 0 10px;
    }
  }
}
</style>